export const HOME = "/";
export const LOGIN = "/login";
export const DASHBOARD = "/dashboard";
export const TRANSTION = "/transaction";
export const USERS = "/user";
export const MERCHANTS = "/merchants";
export const APPROVALS = "/approvals";
export const ACCOUNTSETTING = "/accountsetting";
export const ADMINMANAGMENT = "/adminmanagment";
export const CHANGEEMAIL = "/changeemail";
export const CHANGEEMAILSUCCESS = "/changeemailsuccess";
export const CHANGEPASSWORD = "/changepassword";
export const RESETPASSWORD = "/resetpassword";
export const CHANGEPASSWORDSUCCESS = "/changepasswordsuccess";
export const MERCHANTSDETAILS = "/merchantsdetails";
export const ADCAMPAIGNS = "/addcampaigns";
export const USERDETAILS = "/userdetails";
export const USERKYCMANAGMENT = "/userkycmanagment";
export const DEFAULTFEESSETUP = "/defaultfeessetup";
export const CATEGORYSETUP = "/categorysetup";
export const INVITATION = "/invitations";
export const CMS = "/cms";
export const QBT = "/question-list";
