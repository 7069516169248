import {
  FETCH_ADMIN_PROFILE_REQUEST,
  FETCH_ADMIN_PROFILE_SUCCESS,
  FETCH_ADMIN_PROFILE_FAILURE,
} from "../action/actionTypes";

const initialState = {
  adminProfile: {},
};

const adminProfileData = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ADMIN_PROFILE_REQUEST:
      return state;

    case FETCH_ADMIN_PROFILE_SUCCESS:
      return {
        ...state,
        adminProfile: action.payload,
      };

    case FETCH_ADMIN_PROFILE_FAILURE:
      return state;

    default:
      return state;
  }
};

export default adminProfileData;
