import {
  GET_CMS_PRIVACY,
  GET_CMS_TERMS,
  GET_ALL_CATEGORY,
  ADD_FAQ_CATEGORY,
  GET_ALL_TOPICS,
  ADD_TOPICS,
  ADD_QUESTION,
  GET_ALL_FAQ,
  GET_ALL_FAQ_BY_TOPIC,
} from "../action/actionTypes";
const initialState = {
  cmsPrivacy: {},
  cmsTerms: {},
  addFaqCategory: {},
  getAllCategory: {},
  getAllTopics: {},
  addTopics: {},
  addQuestion: {},
  getAllFaq: {},
  getAllFaqByTopic: false,
};

export const cmsData = (state = initialState, action) => {
  switch (action.type) {
    case GET_CMS_PRIVACY:
      return {
        ...state,
        cmsPrivacy: action.payload,
      };
    case GET_CMS_TERMS:
      return {
        ...state,
        cmsPrivacy: action.payload,
      };
    case ADD_FAQ_CATEGORY:
      return {
        ...state,
        addFaqCategory: action.payload,
      };
    case GET_ALL_CATEGORY:
      return {
        ...state,
        getAllCategory: action.payload,
      };
    case GET_ALL_TOPICS:
      return {
        ...state,
        getAllTopics: action.payload,
      };
    case ADD_TOPICS:
      return {
        ...state,
        addTopics: action.payload,
      };
    case GET_ALL_FAQ:
      return {
        ...state,
        getAllFaq: action.payload,
      };

    case GET_ALL_FAQ_BY_TOPIC:
      return {
        ...state,
        getAllFaqByTopic: action.payload,
      };
    case ADD_QUESTION:
      return {
        ...state,
        addQuestion: action.payload,
      };
    default:
      return state;
  }
};
