import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  addTopicsAction,
  getAllTopicsAction,
} from "../../../../redux/action/Cms/CmsActions";
// import {addAdmin } from '../../../redux/action/SettingAction/SettingAction'
import { approvelsAddAPiRequest } from "../../../../redux/action/Approvels/ApprovelsAction";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdClose } from "react-icons/md";
const AddTopicsModal = (props) => {
  const [isFirst, setIsFirst] = useState(false);
  const [successApiMsg, setSuccessApiMsg] = useState("");
  const [category, setCategory] = useState("");
  const [topic, setTopic] = useState("");

  const dispatch = useDispatch();
  const successMessage = useSelector(
    (state) => state.settingData.addAdminSuccussMsg
  );
  const categoryData = useSelector((state) => state.cmsData.getAllCategory);
  const adminPermission = useSelector(
    (state) => state.loginData.loginSuccesData
  );
  useEffect(() => {
    if (isFirst) {
      if (successMessage?.message) {
        setSuccessApiMsg(successMessage.message);
        setTimeout(() => props.onClick(), 2000);
      }
    }
  }, [successMessage]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const arr = category;
    var xyz = arr.split(",");

    console.log("xyz", xyz);

    let json = {
      categoryId: xyz[0],
      category: xyz[1],
      topic: topic,
    };

    if (adminPermission?.permissions) {
      if (adminPermission.permissions[0].others.blockAdmin === "full_access") {
        const response = await dispatch(addTopicsAction(json));
        console.log("re-----", response);
      } else {
        var sendReq = {
          action: "Add Admin",
          action_status: 1,
          comment: "New admin",
          details: json,
        };
      }
      dispatch(approvelsAddAPiRequest(sendReq));
    } else {
      const response = await dispatch(addTopicsAction(json));
      const Success = response?.payload;

      if (Success && Success.status === true) {
        console.log("success");
        toast.success(Success.message);
        dispatch(getAllTopicsAction());
        props.onClick();
      } else if (Success && Success.data.status === true) {
        toast.success(Success.data.message);
        dispatch(getAllTopicsAction());
        props.onClick();
      } else if (Success && Success.status === 400) {
        toast.error("Please select category");
      } else if (Success && Success.data.status === false) {
        toast.error("Topic already available");
      }
    }
  };

  return (
    <>
      <Modal isOpen={props.isModalOpen} onRequestClose={() => props.onClick()}>
        <div>
          <div className="modal_height">
            <div className="modal-dialog modal-dialog-centered common-modal modal-large">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Add a new Topic
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => props.onClick()}
                  >
                    <MdClose />
                  </button>
                </div>
                <div className="modal-body modal_margin">
                  {successApiMsg ? (
                    <p className="form-text text-center text-success">
                      {successApiMsg}
                    </p>
                  ) : (
                    ""
                  )}

                  <form
                    //   onSubmit={handleSubmit(addAdminHandler)}
                    onSubmit={handleSubmit}
                  >
                    <div className>
                      <div className="row modified">
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor>Add New Topic</label>
                            <input
                              type="text"
                              name="topic"
                              required
                              className="form-control"
                              onChange={(e) => setTopic(e.target.value)}
                              //   ref={register({ required: true })}
                            />
                            <span className="text-danger">
                              {/* {errors.category && "required."} */}
                            </span>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="col">
                            <div className="form-group">
                              <label htmlFor>Select Category</label>
                              <div className="selectImage">
                                <select
                                  className="custom-select"
                                  name="id"
                                  //   ref={register({ required: true })}
                                  onChange={(e) => setCategory(e.target.value)}
                                >
                                  {/* {(categoryData) && categoryData?.data?.result.map((item, index) => {}} */}
                                  <option>Select</option>
                                  {categoryData &&
                                    categoryData?.data?.result.map((option) => {
                                      return (
                                        <option
                                          key={option.id}
                                          value={[option.id, option.category]}
                                        >
                                          {option.category}
                                        </option>
                                      );
                                    })}
                                </select>

                                <span className="text-danger">
                                  {/* {errors.delete_transaction && "required."} */}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="form-group ml-4 mt-3">
                            <button
                              className="btn btn-block green-btn"
                              type="submit"
                              name="button"
                            >
                              Add Topics
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddTopicsModal;
