import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { addFaqCategory } from "../../../../redux/action/Cms/CmsActions";
// import {addAdmin } from '../../../redux/action/SettingAction/SettingAction'
import { approvelsAddAPiRequest } from "../../../../redux/action/Approvels/ApprovelsAction";
import { MdClose } from "react-icons/md";
const AddCategoryModal = (props) => {
  const [isFirst, setIsFirst] = useState(false);
  const [successApiMsg, setSuccessApiMsg] = useState("");

  const dispatch = useDispatch();
  const successMessage = useSelector(
    (state) => state.settingData.addAdminSuccussMsg
  );
  const adminPermission = useSelector(
    (state) => state.loginData.loginSuccesData
  );
  useEffect(() => {
    if (isFirst) {
      if (successMessage?.message) {
        setSuccessApiMsg(successMessage.message);
        setTimeout(() => props.onClick(), 2000);
      }
    }
  }, [successMessage]);

  const { register, handleSubmit, reset, errors } = useForm(); // initialize the hook

  const addAdminHandler = async (data) => {
    const body = {
      category: data.category,
    };
    // console.log(body);
    if (adminPermission?.permissions) {
      if (adminPermission.permissions[0].others.blockAdmin === "full_access") {
        dispatch(addFaqCategory(body));
        props.onClick();
      } else {
        var sendReq = {
          action: "Add Admin",
          action_status: 1,
          comment: "New admin",
          details: body,
        };
      }
      dispatch(approvelsAddAPiRequest(sendReq));
    } else {
      // dispatch(addFaqCategory(body));
      // props.onClick();
      dispatch(addFaqCategory(body));
      props.onClick();
    }

    setIsFirst(true);
  };

  return (
    <>
      <Modal isOpen={props.isModalOpen} onRequestClose={() => props.onClick()}>
        <div>
          <div className="modal_height">
            <div className="modal-dialog modal-dialog-centered common-modal modal-large">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Add a new category
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => props.onClick()}
                  >
                    <MdClose />
                  </button>
                </div>
                <div className="modal-body modal_margin">
                  {successApiMsg ? (
                    <p className="form-text text-center text-success">
                      {successApiMsg}
                    </p>
                  ) : (
                    ""
                  )}

                  <form onSubmit={handleSubmit(addAdminHandler)}>
                    <div className>
                      <div className="">
                        <div className="col">
                          <div className="form-group">
                            {/* <label htmlFor></label> */}
                            <input
                              type="text"
                              name="category"
                              className="form-control"
                              ref={register({ required: true })}
                            />
                            <span className="text-danger">
                              {errors.category && "required."}
                            </span>
                          </div>
                          <div className="form-group mb-0 mt-3">
                            <button
                              className="btn btn-block green-btn"
                              type="submit"
                              name="button"
                            >
                              Add Category
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddCategoryModal;
