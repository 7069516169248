import {
  FETCH_ADMIN_PROFILE_REQUEST,
  FETCH_ADMIN_PROFILE_SUCCESS,
  FETCH_ADMIN_PROFILE_FAILURE,
  GENERATE_TOKEN,
  LOGOUT,
} from "../actionTypes";

import { adminProfileApi } from "../../../api/ApiService";
import { validateToken } from "../../../utils";

export const fetchAdminProfile = () => {
  return async (dispatch) => {
    dispatch({ type: FETCH_ADMIN_PROFILE_REQUEST });

    try {
      console.log("Fetching admin profile...");

      const tokenResponse = await validateToken();
      dispatch({ type: GENERATE_TOKEN, payload: tokenResponse });

      const response = await adminProfileApi();

      if (response && response.status === 200) {
        console.log("Admin profile data:", response.data);

        if (response.data.status === 0) {
          console.log("Admin is blocked. Logging out...");

          dispatch({ type: LOGOUT });

          window.location.href = "/login";
        } else {
          dispatch({
            type: FETCH_ADMIN_PROFILE_SUCCESS,
            payload: response.data,
          });
        }
      } else {
        console.error("Failed to fetch admin profile. Response:", response);
        dispatch({
          type: FETCH_ADMIN_PROFILE_FAILURE,
          error: "Failed to fetch admin profile",
        });
      }
    } catch (error) {
      console.error("Error fetching admin profile:", error);
      dispatch({ type: FETCH_ADMIN_PROFILE_FAILURE, error: error.message });
    }
  };
};
