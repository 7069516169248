import {
  GENERATE_TOKEN,
  GET_CMS_PRIVACY,
  GET_CMS_TERMS,
  GET_ALL_CATEGORY,
  ADD_FAQ_CATEGORY,
  ADD_FAQ_CATEGORY_ERROR,
  DELETE_FAQ_CATEGORY,
  GET_ALL_TOPICS,
  ADD_TOPICS,
  ADD_TOPICS_ERROR,
  ADD_QUESTION,
  ADD_QUESTION_ERROR,
  EDIT_TOPICS,
  GET_ALL_FAQ,
  UPDATE_PRIVACY,
  UPDATE_PRIVACY_ERROR,
  GET_ALL_FAQ_BY_TOPIC,
  UPDATE_TERMS,
  UPDATE_TERMS_ERROR,
  DELETE_FAQ_TOPIC,
  DELETE_QUESTION,
  EDIT_QUESTION,
  EDIT_QUESTION_ERROR,
} from "../actionTypes";
import {
  getCmsPrivacy,
  getCmsTerms,
  getAllCategroy,
  addFaqCategroy,
  editFaqCategroy,
  deleteFaqCategroy,
  getAllTopics,
  addTopics,
  addQuestion,
  editTopics,
  getAllFaq,
  updatePrivacy,
  getAllFaqByTopic,
  updateTerms,
  deleteFaqTopic,
  deleteQuestion,
  editQuestion,
} from "../../../api/ApiService";
import store from "../../store/store";
import { validateToken } from "../../../utils";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { css } from "glamor";

export const getCmsPrivacyData = (data) => {
  return async (dispatch) => {
    const response = await validateToken();
    dispatch({ type: GENERATE_TOKEN, payload: response });
    return getCmsPrivacy(data)
      .then((response) => {
        return dispatch({
          type: GET_CMS_PRIVACY,
          payload: response,
        });
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const updatePrivacyAction = (data) => {
  return async (dispatch) => {
    const response = await validateToken();
    dispatch({ type: GENERATE_TOKEN, payload: response });

    updatePrivacy(data)
      .then((response) => {
        if (response && response.status && response.status === 200) {
          if (response.data.status === true) {
            toast.success(response?.data?.message);
          } else {
            toast.error(response?.data?.message);
          }
          dispatch({ type: UPDATE_PRIVACY, payload: response.data });
          dispatch(getAllCategoryAction());
        }
      })
      .catch(({ ...error }) => {
        dispatch({ type: UPDATE_PRIVACY_ERROR, payload: error.response });
        throw error;
      });
  };
};

export const updateTermsAction = (data) => {
  return async (dispatch) => {
    const response = await validateToken();
    dispatch({ type: GENERATE_TOKEN, payload: response });
    updateTerms(data)
      .then((response) => {
        if (response && response.status && response.status === 200) {
          if (response.data.status === true) {
            toast.success(response?.data?.message);
          } else {
            toast.error(response?.data?.message);
          }
          dispatch({ type: UPDATE_TERMS, payload: response.data });
          dispatch(getAllCategoryAction());
        }
      })
      .catch(({ ...error }) => {
        dispatch({ type: UPDATE_TERMS_ERROR, payload: error.response });
        throw error;
      });
  };
};

export const getCmsTermsData = (data) => {
  return async (dispatch) => {
    const response = await validateToken();
    dispatch({ type: GENERATE_TOKEN, payload: response });
    return getCmsTerms(data)
      .then((response) => {
        return dispatch({
          type: GET_CMS_TERMS,
          payload: response,
        });
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const addFaqCategory = (data) => {
  return async (dispatch) => {
    const response = await validateToken();
    dispatch({ type: GENERATE_TOKEN, payload: response });
    addFaqCategroy(data)
      .then((response) => {
        if (response && response.status && response.status === 200) {
          if (response.data.status === true) {
            toast.success(response?.data?.message);
          } else {
            toast.error(response?.data?.message);
          }
          dispatch({ type: ADD_FAQ_CATEGORY, payload: response.data });
          dispatch(getAllCategoryAction());
        }
      })
      .catch(({ ...error }) => {
        dispatch({ type: ADD_FAQ_CATEGORY_ERROR, payload: error.response });
        throw error;
      });
  };
};

export const editFaqCategory = (data, id) => {
  return async (dispatch) => {
    const response = await validateToken();
    dispatch({ type: GENERATE_TOKEN, payload: response });
    return editFaqCategroy(data, id)
      .then((response) => {
        return dispatch({ type: ADD_FAQ_CATEGORY, payload: response.data });
        // if (response && response.status && response.status === 200) {
        //   if (response.data.status === true) {
        //     toast.success(response?.data?.message);
        //   } else {
        //     toast.error(response?.data?.message);
        //   }
        //   dispatch(getAllCategoryAction());
        // }
      })
      .catch(({ ...error }) => {
        dispatch({ type: ADD_FAQ_CATEGORY_ERROR, payload: error.response });
        throw error;
      });
  };
};
export const deleteFaqCategory = (id) => {
  return async (dispatch) => {
    const response = await validateToken();
    dispatch({ type: GENERATE_TOKEN, payload: response });
    return deleteFaqCategroy(id)
      .then((response) => {
        return dispatch({ type: DELETE_FAQ_CATEGORY, payload: response.data });
        // if (response && response.status && response.status === 200) {
        //   if (response.data.status === true) {
        //     toast.error(response?.data?.message);
        //   } else {
        //     toast.error(response?.data?.message);
        //   }
        //   dispatch(getAllCategoryAction());
        //   // console.log("id--", response);
        // }
      })
      .catch(({ ...error }) => {
        dispatch({ type: ADD_FAQ_CATEGORY_ERROR, payload: error.response });
        throw error;
      });
  };
};

export const getAllCategoryAction = (data) => {
  return async (dispatch) => {
    const response = await validateToken();
    dispatch({ type: GENERATE_TOKEN, payload: response });
    return getAllCategroy(data)
      .then((response) => {
        return dispatch({
          type: GET_ALL_CATEGORY,
          payload: response,
        });
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const getAllTopicsAction = (data) => {
  return async (dispatch) => {
    const response = await validateToken();
    dispatch({ type: GENERATE_TOKEN, payload: response });
    return getAllTopics(data)
      .then((response) => {
        return dispatch({
          type: GET_ALL_TOPICS,
          payload: response,
        });
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const addTopicsAction = (data) => {
  return async (dispatch) => {
    const response = await validateToken();
    dispatch({ type: GENERATE_TOKEN, payload: response });
    return addTopics(data)
      .then((response) => {
        return dispatch({ type: ADD_TOPICS, payload: response.data });
      })
      .catch(({ ...error }) => {
        return dispatch({ type: ADD_TOPICS_ERROR, payload: error.response });
      });
  };
};

// export const addTopicsAction = (data) => {
//   return async (dispatch) => {
//     const response = await validateToken();
//     dispatch({ type: GENERATE_TOKEN, payload: response });
//     return addTopics(data)
//       .then((response) => {
//         if (response && response.status && response.status === 200) {
//           if (response.data.status === true) {
//             toast.success(response?.data?.message);
//             // console.log(response?.data?.message);
//           } else {
//             toast.error(response?.data?.message);
//           }
//           dispatch({ type: ADD_TOPICS, payload: response.data });
//           // toast.error("error---");
//           dispatch(getAllTopicsAction());
//         }
//       })
//       .catch(({ ...error }) => {
//         if (error.response.data.message === "400! Bad Request") {
//           toast.error("Please select a category");
//         } else {
//           toast.error("Category already available");
//         }
//         // dispatch({ type: ADD_TOPICS_ERROR, payload: error.response });
//         // toast.error(error.response.data.message);
//         // toast.error("Please select a category");
//         // throw error;
//       });
//   };
// };
export const editTopicsAction = (data) => {
  return async (dispatch) => {
    const response = await validateToken();
    dispatch({ type: GENERATE_TOKEN, payload: response });
    editTopics(data)
      .then((response) => {
        if (response && response.status && response.status === 200) {
          if (response.data.status === true) {
            toast.success(response?.data?.message);
          } else {
            toast.error(response?.data?.message);
          }
          dispatch({ type: EDIT_TOPICS, payload: response.data });
          dispatch(getAllTopicsAction());
        }
      })
      .catch(({ ...error }) => {
        dispatch({ type: ADD_TOPICS_ERROR, payload: error.response });
        toast.error("Topic already available");
        // throw error;
      });
  };
};

export const deleteFaqTopicAction = (data) => {
  return async (dispatch) => {
    const response = await validateToken();
    dispatch({ type: GENERATE_TOKEN, payload: response });
    return deleteFaqTopic(data)
      .then((response) => {
        return dispatch({ type: DELETE_FAQ_TOPIC, payload: response.data });
      })
      .catch(({ ...error }) => {
        throw error;
      });
  };
};

export const getAllFaqAction = (data) => {
  return async (dispatch) => {
    const response = await validateToken();
    dispatch({ type: GENERATE_TOKEN, payload: response });
    getAllFaq(data)
      .then((response) => {
        if (response && response.status && response.status === 200) {
          dispatch({ type: GET_ALL_FAQ, payload: response.data });
          // dispatch(getAllCategoryAction ())
        }
      })
      .catch(({ ...error }) => {
        throw error;
      });
  };
};

export const getAllFaqByTopicAction = (id) => {
  return async (dispatch) => {
    const response = await validateToken();
    dispatch({ type: GENERATE_TOKEN, payload: response });
    getAllFaqByTopic(id)
      .then((response) => {
        if (response && response.status && response.status === 200) {
          dispatch({ type: GET_ALL_FAQ_BY_TOPIC, payload: response.data });
          // dispatch(getAllCategoryAction ())
        }
      })
      .catch(({ ...error }) => {
        throw error;
      });
  };
};

export const addQuestionAction = (data) => {
  return async (dispatch) => {
    const response = await validateToken();
    dispatch({ type: GENERATE_TOKEN, payload: response });
    return addQuestion(data)
      .then((response) => {
        const promise1 = Promise.resolve(
          dispatch({ type: ADD_QUESTION, payload: response.data })
        );
        const id = 1;
        const promise2 = new Promise((resolve) => {
          setTimeout(() => {
            resolve(dispatch(getAllFaqByTopicAction(id)));
          }, 1000);
        });
        return Promise.all([promise1, promise2]);
        // return dispatch({ type: ADD_QUESTION, payload: response.data });
      })
      .catch(({ ...error }) => {
        return dispatch({ type: ADD_QUESTION_ERROR, payload: error.response });
      });
  };
};

export const deleteQuestionAction = (data) => {
  return async (dispatch) => {
    const response = await validateToken();
    dispatch({ type: GENERATE_TOKEN, payload: response });
    return deleteQuestion(data)
      .then((response) => {
        return dispatch({ type: DELETE_QUESTION, payload: response });
      })
      .catch(({ ...error }) => {
        throw error;
      });
  };
};
export const editQuestionAction = (data) => {
  return async (dispatch) => {
    const response = await validateToken();
    dispatch({ type: GENERATE_TOKEN, payload: response });
    return editQuestion(data)
      .then((response) => {
        return dispatch({ type: EDIT_QUESTION, payload: response.data });
      })
      .catch(({ ...error }) => {
        dispatch({ type: EDIT_QUESTION_ERROR, payload: error.response });
        throw error;
      });
  };
};
