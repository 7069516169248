import React from "react";
import { useEffect, useState } from "react";
import { TextField, Typography, Button, ButtonGroup } from "@material-ui/core";
import { Create, Delete, CheckCircle } from "@material-ui/icons";

import swal from "sweetalert";
import { useDispatch } from "react-redux";
import { addCategory } from "../../redux/action/Cms/CmsActions";
import "./faq.css";
import {
  TabContainer,
  TabPane,
  TabContent,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
} from "react-bootstrap";
import Category from "./faqs/category/Category";
import Topics from "./faqs/topics/Topics";
import Questions from "./faqs/questions/Questions";
import QuestionsByTopics from "./faqs/questions/QuestionsByTopics";

const Faq = () => {
  const initialTutorialState = {
    id: null,
    category: "",
    published: false,
  };
  const [addCategory, setAddCategory] = useState(initialTutorialState);
  const [submitted, setSubmitted] = useState(false);
  const dispatch = useDispatch();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setAddCategory({ ...addCategory, [name]: value });
  };

  const saveCatogery = () => {
    const { category } = addCategory;
    dispatch(addCategory(category))
      .then((data) => {
        setAddCategory({
          id: data.id,
          category: data.category,
          published: data.published,
        });
        setSubmitted(true);
        console.log(data);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const newTutorial = () => {
    setAddCategory(initialTutorialState);
    setSubmitted(false);
  };

  return (
    <>
      <section
        style={{
          margin: "0px auto",
          paddingBottom: "10px",

          borderRadius: "9px",
          boxShadow: "0px 0px 12px -3px #000000",
        }}
      >
        <TabContainer
          id="left-tabs-example"
          defaultActiveKey="first"
          className="container-fluid"
        >
          <div className="" style={{ borderBottom: "1px solid #F4F4F4" }}>
            <Nav variant="pills" class="navbar navbar-expand-lg navbar-light">
              {/* {/* <a class="navbar-brand" href="#">CMS</a> */}
              <button
                class="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span class="navbar-toggler-icon"></span>
              </button>
              <div
                class="collapse navbar-collapse faq"
                id="navbarSupportedContent"
              >
                {/* <ul class="navbar-nav  mr-auto">
              </ul> */}
                <form class="form-inline my-2 my-lg-0">
                  <NavItem>
                    <NavLink className="faq_link" eventKey="first">
                      Category
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink eventKey="second">Topics</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink eventKey="third">Question</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink eventKey="fourth">Questions By Topics</NavLink>
                  </NavItem>
                </form>
              </div>
            </Nav>
          </div>
          <div className="transaction-main">
            {/* <PrivacyPolicy /> */}
            <TabContent>
              <TabPane eventKey="first">
                <Category />
              </TabPane>
            </TabContent>
            <TabContent>
              <TabPane eventKey="second">
                <Topics />
              </TabPane>
            </TabContent>
            <TabContent>
              <TabPane eventKey="third">
                <Questions />
              </TabPane>
            </TabContent>
            <TabContent>
              <TabPane eventKey="fourth">
                <QuestionsByTopics />
              </TabPane>
            </TabContent>
          </div>
        </TabContainer>
      </section>
    </>
  );
};

export default Faq;
