import React, { useEffect, useState } from "react";
// import '../AdminManagment/AdminManagment.css'

// import AddNewAdminModal from './AddNewAdminModal'
// import EditAdminModal from './EditAdminModal'
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";

import {
  deleteFaqCategory,
  getAllCategoryAction,
} from "../../../../redux/action/Cms/CmsActions";
// import { approvelsAddAPiRequest } from '../../../redux/action/Approvels/ApprovelsAction'
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Typography, Button, ButtonGroup } from "@material-ui/core";
import { Create, Delete, CheckCircle } from "@material-ui/icons";
import AddCategoryModal from "./AddCategoryModal";
import EditCateroryModal from "./EditCateroryModal";
import { toast } from "react-toastify";

const Category = (category, faqCategory) => {
  const dispatch = useDispatch();
  const categoryData = useSelector((state) => state.cmsData.getAllCategory);
  const successMessage = useSelector(
    (state) => state.settingData.addAdminSuccussMsg
  );
  const adminPermission = useSelector(
    (state) => state.loginData.loginSuccesData
  );
  const [isAddAdmin, setIsAddAdmin] = useState(false);
  const [isEditAdmin, setIsEditAdmin] = useState(false);
  const [isEditAdminData, setIsEditAdminData] = useState("");

  // console.log("cat--", categoryData?.data?.result);
  useEffect(() => {
    dispatch(getAllCategoryAction());
  }, []);
  const addAdminModelClose = () => {
    setIsAddAdmin(false);
  };

  const editAdminModel = (item) => {
    setIsEditAdminData(item);
    setIsEditAdmin(!isEditAdmin);
  };
  const editAdminModelClose = () => {
    setIsEditAdmin(false);
  };
  const deleteAdminById = async (id) => {
    // const status = 0;
    const res = await dispatch(deleteFaqCategory(id));

    const success = res.payload;

    if (success && success.status === true) {
      toast.error(success.message);
      window.location.reload();
    }
  };
  const renderTableData = () => {
    return (
      !isEmpty(categoryData) &&
      categoryData?.data?.result.map((item, index) => {
        const { id, category } = item;

        return (
          <tr key={index}>
            <td>#{index + 1}</td>

            <td>
              <div className="">
                <span className="sender-txt">{category}</span>
              </div>
            </td>
            {/* <td>{email}</td>
          <td>{role}</td> */}

            <td>
              <a
                data-toggle="modal"
                data-target="#addAdminModal-2"
                className={`action-link ${
                  id === 2 || id === 1 ? "disabled" : ""
                }`}
                onClick={() => {
                  if (id === 2 || id === 1) {
                    return true;
                  } else {
                    editAdminModel(item);
                  }
                }}
              >
                <i className="icon-icon-edit" />
              </a>

              <a
                data-toggle="modal"
                data-target="#addAdminModal-2"
                className={`action-link ${
                  id === 2 || id === 1 ? "disabled" : ""
                }`}
                onClick={() => {
                  if (id === 2 || id === 1) {
                    return true;
                  } else {
                    deleteAdminById(id);
                  }
                }}
              >
                <i className="icon-icon-delete" />
              </a>
            </td>
          </tr>
        );
      })
    );
  };

  return (
    <>
      <AddCategoryModal
        isModalOpen={isAddAdmin}
        onClick={() => addAdminModelClose()}
      />
      {isEditAdmin && (
        <EditCateroryModal
          isModalOpen={isEditAdmin}
          onClick={() => editAdminModelClose()}
          data={isEditAdminData}
        />
      )}
      <div
        style={{
          margin: "20px",
          padding: "10px 20px",
          // border: "1px solid #bdbdbd",
          borderRadius: "5px",
        }}
      >
        <section className="">
          <div className="sec-block alt first">
            <div className="block-single auto">
              <div className="block-heading mb-3">
                <h2>Categories</h2>
                <div className="table-btn">
                  <a
                    data-toggle="modal"
                    data-target="#addAdminModal"
                    className={`link green 
                  ${
                    adminPermission.role === "admin" &&
                    adminPermission.permissions[0].others.addAdmin ===
                      "view_only"
                      ? "disabled"
                      : ""
                  }`}
                    onClick={() => {
                      if (
                        adminPermission.role === "admin" &&
                        adminPermission.permissions[0].others.addAdmin ===
                          "view_only"
                      ) {
                        return true;
                      } else {
                        setIsAddAdmin(!isAddAdmin);
                      }
                    }}
                  >
                    Add a new category
                  </a>
                </div>
              </div>
              <div className="transaction-main">
                <div className="transaction-table">
                  <div className="table-responsive">
                    <table className="table theme-table">
                      <tbody>
                        <tr>
                          <th>Category ID</th>
                          {/* <th>Date added</th> */}
                          <th>Category Name</th>
                          {/* <th>Email</th>
                          <th>Role</th>
                          <th>Last visit</th> */}
                          <th style={{ width: "160px" }}>Action</th>
                        </tr>

                        {renderTableData()}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Category;
