import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  addFaqCategory,
  addQuestionAction,
  editQuestionAction,
} from "../../../../redux/action/Cms/CmsActions";
// import {addAdmin } from '../../../redux/action/SettingAction/SettingAction'
import { approvelsAddAPiRequest } from "../../../../redux/action/Approvels/ApprovelsAction";
import { toast } from "react-toastify";
import { MdClose } from "react-icons/md";
const EditQuestions = (props) => {
  const [isFirst, setIsFirst] = useState(false);
  const [successApiMsg, setSuccessApiMsg] = useState("");

  const dispatch = useDispatch();
  const successMessage = useSelector(
    (state) => state.settingData.addAdminSuccussMsg
  );
  const adminPermission = useSelector(
    (state) => state.loginData.loginSuccesData
  );
  const categoryData = useSelector((state) => state.cmsData.getAllCategory);
  const topicsData = useSelector((state) => state.cmsData.getAllTopics);

  const [category, setCategory] = useState("");
  const [topic, setTopic] = useState("");
  const [question, setQuestion] = useState("");
  const [editCategory, setEditCategory] = useState("");
  const [editTopic, setEditTopic] = useState("");
  const [answer, setAnswer] = useState("");

  useEffect(() => {
    if (isFirst) {
      if (successMessage?.message) {
        setSuccessApiMsg(successMessage.message);
        setTimeout(() => props.onClick(), 2000);
      }
    }
  }, [successMessage]);

  useEffect(() => {
    setQuestion(props.data.question);
    setAnswer(props.data.answer);
    setCategory(props.data.category);
    setTopic(props.data.topic);
  }, [props]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    let json = {
      id: props.data.id,
      data: {
        category: category,
        topic: topic,
        question: question,
        answer: answer,
      },
    };
    // console.log("json---", json);

    if (adminPermission?.permissions) {
      if (adminPermission.permissions[0].others.blockAdmin === "full_access") {
        dispatch(editQuestionAction(json));
      } else {
        var sendReq = {
          action: "Add Admin",
          action_status: 1,
          comment: "New admin",
          details: json,
        };
      }
      dispatch(approvelsAddAPiRequest(sendReq));
    } else {
      const res = await dispatch(editQuestionAction(json));

      if (res && res.payload.status === true) {
        toast.success(res.payload.message);

        props.onClick();
        window.location.reload();
      }
      // props.onClick();
      // window.location.reload();
    }
  };

  return (
    <>
      <Modal isOpen={props.isModalOpen} onRequestClose={() => props.onClick()}>
        <div>
          <div className="modal_height">
            <div className="modal-dialog modal-dialog-centered common-modal modal-large">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Edit
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => props.onClick()}
                  >
                    <MdClose />
                  </button>
                </div>
                <div className="modal-body modal_margin">
                  {successApiMsg ? (
                    <p className="form-text text-center text-success">
                      {successApiMsg}
                    </p>
                  ) : (
                    ""
                  )}

                  <form
                    onSubmit={handleSubmit}
                    // onSubmit={handleSubmit(addAdminHandler)}
                  >
                    <div className>
                      <div className="row modified">
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor>Edit Question</label>
                            <input
                              type="text"
                              name="question"
                              className="form-control"
                              defaultValue={props.data.question}
                              //   ref={register({ required: true })}
                              onChange={(e) => setQuestion(e.target.value)}

                              // onChange={handleInputChange}
                            />
                            {/* <span className="text-danger">{errors.category && 'required.'}</span> */}
                          </div>
                          <div className="form-group">
                            <label htmlFor>Edit Answer</label>
                            <textarea
                              type="text"
                              name="answer"
                              className="form-control"
                              defaultValue={props.data.answer}
                              onChange={(e) => setAnswer(e.target.value)}
                              // ref={register({ required: true })}
                            />
                            {/* <span className="text-danger">{errors.category && 'required.'}</span> */}
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="col">
                            <div className="form-group">
                              <label htmlFor>Select Category</label>
                              <div className="selectImage">
                                <select
                                  className="custom-select"
                                  name="category"
                                  onChange={(e) => setCategory(e.target.value)}
                                  defaultValue={props.data.category}
                                >
                                  {categoryData &&
                                    categoryData?.data?.result.map((option) => {
                                      // console.log(option);
                                      return (
                                        <option
                                          key={option.id}
                                          value={[option.category]}
                                        >
                                          {option.category}
                                        </option>
                                      );
                                    })}
                                </select>
                                {/* <span className="text-danger">{errors.delete_transaction && 'required.'}</span> */}
                              </div>
                            </div>
                          </div>
                          <div className="col my-2 mt-4">
                            <div className="form-group">
                              <label htmlFor>Select Topic</label>
                              <div className="selectImage">
                                <select
                                  className="custom-select"
                                  name="topic"
                                  onChange={(e) => setTopic(e.target.value)}
                                  defaultValue={props.data.topic}
                                >
                                  {topicsData &&
                                    topicsData?.data?.data.map((option) => {
                                      return (
                                        <option value={option.topic}>
                                          {option.topic}
                                        </option>
                                      );
                                    })}
                                </select>
                                {/* <span className="text-danger">{errors.delete_transaction && 'required.'}</span> */}
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group ml-3 mt-3">
                              <button
                                className="btn btn-block green-btn"
                                type="submit"
                                name="button"
                              >
                                Edit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EditQuestions;
