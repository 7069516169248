import React from "react";
import "./cms.css";
import Header from "../../component/Header";
import {
  TabContainer,
  TabPane,
  TabContent,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
} from "react-bootstrap";
import PrivacyPolicy from "./PrivacyPolicy";
import Terms from "./Terms";
import Faq from "./Faq";

const Cms = () => {
  return (
    <>
      <div>
        <Header />
        <TabContainer
          id="left-tabs-example"
          defaultActiveKey="first"
          className="container-fluid"
        >
          <section className="dash-wrap">
            <div className="sec-block alt first">
              <div className="cms-single auto">
                <div className="cms-heading mb-3">
                  <Nav
                    variant="pills"
                    class="navbar navbar-expand-lg navbar-light"
                  >
                    <a class="navbar-brand" href="#">
                      CMS
                    </a>
                    <button
                      class="navbar-toggler"
                      type="button"
                      data-toggle="collapse"
                      data-target="#navbarSupportedContent"
                      aria-controls="navbarSupportedContent"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      <span class="navbar-toggler-icon"></span>
                    </button>
                    <div
                      class="collapse navbar-collapse"
                      id="navbarSupportedContent"
                    >
                      <ul class="navbar-nav  mr-auto"></ul>
                      <form class="form-inline my-2 my-lg-0">
                        <NavItem>
                          <NavLink eventKey="first">Privacy Policy</NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink eventKey="second">
                            Terms & Conditions
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink eventKey="third">FAQ</NavLink>
                        </NavItem>
                      </form>
                    </div>
                  </Nav>
                </div>
                <div className="transaction-main">
                  {/* <PrivacyPolicy /> */}
                  <TabContent>
                    <TabPane eventKey="first">
                      <PrivacyPolicy />
                    </TabPane>
                  </TabContent>
                  <TabContent>
                    <TabPane eventKey="second">
                      <Terms />
                    </TabPane>
                  </TabContent>
                  <TabContent>
                    <TabPane eventKey="third">
                      <Faq />
                    </TabPane>
                  </TabContent>
                </div>
              </div>
            </div>
          </section>
        </TabContainer>
      </div>
    </>
  );
};

export default Cms;
