import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useEffect, useState } from "react";
import swal from "sweetalert";
import { useDispatch, useSelector } from "react-redux";
import {
  getCmsPrivacyData,
  updatePrivacyAction,
} from "../../redux/action/Cms/CmsActions";
import "./cms.css";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import { approvelsAddAPiRequest } from "../../redux/action/Approvels/ApprovelsAction";

const PrivacyPolicy = (props) => {
  const dispatch = useDispatch();
  const adminPermission = useSelector(
    (state) => state.loginData.loginSuccesData
  );
  const [cmsPrivacy, setCmsPrivacy] = useState("");
  const [privacy, setPrivacy] = useState("");
  useEffect(() => {
    getCmsPrivacy();
  }, []);

  const getCmsPrivacy = async () => {
    try {
      const response = await dispatch(getCmsPrivacyData({}));
      const getCmsPrivacySuccess = response?.payload;
      if (getCmsPrivacySuccess) {
        if (getCmsPrivacySuccess?.status == 200) {
          setCmsPrivacy(getCmsPrivacySuccess?.data);
        } else {
          swal({
            title: "Error!",
            text: getCmsPrivacySuccess?.data?.message,
            buttons: false,
            icon: "error",
            timer: 2000,
          });
        }
      }
    } catch (err) {
      swal({
        title: "Error",
        text: err?.message,
        buttons: false,
        icon: "error",
        timer: 2000,
      });
    }
  };

  const handleFormdata = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setCmsPrivacy({
      ...cmsPrivacy,
      [name]: value,
    });
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const body = {
      content: cmsPrivacy,
    };

    if (adminPermission?.permissions) {
      if (adminPermission.permissions[0].others.blockAdmin === "full_access") {
        dispatch(updatePrivacyAction(body));
      } else {
        var sendReq = {
          action: "Add Admin",
          action_status: 1,
          comment: "New admin",
          details: body,
        };
      }
      dispatch(approvelsAddAPiRequest(sendReq));
      props.onClick();
    } else {
      dispatch(updatePrivacyAction(body));
    }
  };

  return (
    <>
      <form action="" onSubmit={handleEditSubmit}>
        <div>
          <div style={{ margin: "10px" }}>
            <CKEditor
              editor={ClassicEditor}
              data={cmsPrivacy}
              onChange={(event, editor) => setCmsPrivacy(editor.getData())}
            />
          </div>
          <button type="submit" class="btn green-btn-privacy">
            Update
          </button>
        </div>
      </form>
    </>
  );
};

export default PrivacyPolicy;
