import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  addFaqCategory,
  addQuestionAction,
  deleteQuestionAction,
  getAllFaqByTopicAction,
} from "../../../../redux/action/Cms/CmsActions";
import { useHistory } from "react-router-dom";
// import {addAdmin } from '../../../redux/action/SettingAction/SettingAction'
import { approvelsAddAPiRequest } from "../../../../redux/action/Approvels/ApprovelsAction";
import { toast } from "react-toastify";
import { MdClose } from "react-icons/md";
const DeleteQuestionsModal = (props) => {
  const [isFirst, setIsFirst] = useState(false);
  const [successApiMsg, setSuccessApiMsg] = useState("");
  // console.log("prop--", props);
  const history = useHistory();

  const dispatch = useDispatch();
  const successMessage = useSelector(
    (state) => state.settingData.addAdminSuccussMsg
  );
  const adminPermission = useSelector(
    (state) => state.loginData.loginSuccesData
  );
  const categoryData = useSelector((state) => state.cmsData.getAllCategory);
  const topicsData = useSelector((state) => state.cmsData.getAllTopics);

  const [category, setCategory] = useState("");
  const [topic, setTopic] = useState("");
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  // console.log("cat--", categoryData?.data?.result);
  useEffect(() => {
    if (isFirst) {
      if (successMessage?.message) {
        setSuccessApiMsg(successMessage.message);
        setTimeout(() => props.onClick(), 2000);
      }
    }
  }, [successMessage]);

  const deleteAdminById = async (id) => {
    const json = {
      id: props.data.id,
    };

    const res = await dispatch(deleteQuestionAction(json));

    if (res && res.payload.data.status === true) {
      console.log("success");
      toast.error(res.payload.data.message);
      props.onClick();
      window.location.reload();
    }
  };

  return (
    <Modal isOpen={props.isModalOpen} onRequestClose={() => props.onClick()}>
      <div>
        <div className="" id="deleteModal" tabIndex={-1} aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered common-modal size-2">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Question ID #{props.data.id}{" "}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => props.onClick()}
                >
                  <MdClose />
                </button>
              </div>
              <div className="modal-body">
                <div className="userModalCont">
                  <h2 className="modalTitle">Are you sure to delete?</h2>
                  {/* <div className="">
                    <div className="userBlock-txt">
                      <h2>{props.data.question}</h2>
                      <p>+{props.data.answer}</p>
                    </div>
                  </div> */}

                  <div className="row modified">
                    <div className="col-sm-6">
                      <button
                        className="btn btn-block red-btn mt-4"
                        type="submit"
                        onClick={() => deleteAdminById()}
                      >
                        Delete
                      </button>
                    </div>
                    <div className="col-sm-6">
                      <button
                        data-dismiss="modal"
                        className="btn btn-block grey-btn mt-4"
                        onClick={() => props.onClick()}
                        type="button"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteQuestionsModal;
