import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getAllFaqByTopicAction } from "../../../../redux/action/Cms/CmsActions";
import {
  TextField,
  Typography,
  Button,
  ButtonGroup,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { Create, Delete, CheckCircle, ExpandMore } from "@material-ui/icons";
import swal from "sweetalert";
import EditQuestions from "./EditQuestions";
import DeleteQuestionsModal from "./DeleteQuestionsModal";
const QuestionsByTopics = () => {
  const dispatch = useDispatch();
  const allTopicData = useSelector((state) => state.cmsData.getAllFaqByTopic);
  const topicsData = useSelector((state) => state.cmsData.getAllTopics);
  const adminPermission = useSelector(
    (state) => state.loginData.loginSuccesData
  );

  // console.log("all--", allTopicData);
  const [isAddAdmin, setIsAddAdmin] = useState(false);
  const [isEditAdmin, setIsEditAdmin] = useState(false);
  const [isEditAdminData, setIsEditAdminData] = useState("");

  const [isDeleteQuestion, setDeleteQuestion] = useState(false);
  const [isDeleteQuestionData, setIsDeleteQuestionData] = useState("");

  const { register, handleSubmit, reset, errors } = useForm("1");
  const [id, setId] = useState(1);
  const [value, setValue] = useState("");

  useEffect(() => {
    dispatch(getAllFaqByTopicAction(id));
  }, [id]);

  const addAdminHandler = async (data) => {
    const iid = data.id;
    setId(iid);
  };
  const editAdminModel = (option) => {
    setIsEditAdminData(option);
    setIsEditAdmin(!isEditAdmin);
  };
  const editAdminModelClose = () => {
    setIsEditAdmin(false);
  };
  const deleteAdminModel = (option) => {
    setIsDeleteQuestionData(option);
    setDeleteQuestion(!isEditAdmin);
  };
  const deleteAdminModelClose = () => {
    setDeleteQuestion(false);
  };
  const renderTableData = () => {
    return (
      <div>
        {allTopicData &&
          allTopicData?.data.map((option) => {
            return (
              <>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography>{option.question}</Typography>
                    <Typography
                      style={{ marginLeft: "10px", fontSize: "12px" }}
                    >
                      <a
                        style={{ width: "25px", height: "25px" }}
                        data-toggle="modal"
                        data-target="#addAdminModal-2"
                        className={`action-link ${
                          adminPermission.role === "admin" &&
                          adminPermission.permissions[0].others.editAdmin ===
                            "view_only"
                            ? "disabled"
                            : ""
                        }`}
                        onClick={() => {
                          if (
                            adminPermission.role === "admin" &&
                            adminPermission.permissions[0].others.editAdmin ===
                              "view_only"
                          ) {
                            return true;
                          } else {
                            editAdminModel(option);
                          }
                        }}
                      >
                        <i className="icon-icon-edit" />
                      </a>

                      <a
                        style={{ width: "25px", height: "25px" }}
                        className="action-link"
                        href="#"
                        onClick={() => {
                          if (
                            adminPermission.role === "admin" &&
                            adminPermission.permissions[0].others.editAdmin ===
                              "view_only"
                          ) {
                            return true;
                          } else {
                            deleteAdminModel(option);
                          }
                        }}
                      >
                        <i className="icon-icon-delete" />
                      </a>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography style={{ wordBreak: "break-all" }}>
                      {option.answer}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </>
            );
          })}
      </div>
    );
  };

  return (
    <>
      <EditQuestions
        isModalOpen={isEditAdmin}
        onClick={() => editAdminModelClose()}
        data={isEditAdminData}
      />
      <DeleteQuestionsModal
        isModalOpen={isDeleteQuestion}
        onClick={() => deleteAdminModelClose()}
        data={isDeleteQuestionData}
      />
      <div
        style={{
          margin: "20px",
          padding: "10px 20px",
          // border: "1px solid #bdbdbd",
          borderRadius: "5px",
        }}
      >
        <section className="">
          <div className="sec-block alt first">
            <div className="block-single auto">
              <div className="block-heading mb-3">
                <h2>Questions & Answer</h2>
                <div className="table-btn">
                  <form action="" onChange={handleSubmit(addAdminHandler)}>
                    <select
                      className="custom-select"
                      name="id"
                      ref={register({ required: true })}
                    >
                      {topicsData &&
                        topicsData?.data?.data.map((option) => {
                          return (
                            <option value={option.id}>{option.topic}</option>
                          );
                        })}
                    </select>
                  </form>
                </div>
              </div>
              <div className="transaction-main">
                <div className="transaction-table">
                  <div className="table-responsive">{renderTableData()}</div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default QuestionsByTopics;
