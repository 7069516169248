import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux'
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './redux/store/store'
import { PersistGate } from 'redux-persist/integration/react'
import {persistor} from './redux/store/store'
import { BrowserRouter } from 'react-router-dom';


ReactDOM.render(
  <React.StrictMode>
     <Provider store={store}>
       <PersistGate loading={null} persistor={persistor}>
    <BrowserRouter>
        <App />
  </BrowserRouter>,
       </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);


reportWebVitals();


