import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useEffect, useState } from "react";
import swal from "sweetalert";
import { useDispatch, useSelector } from "react-redux";
import {
  getCmsTermsData,
  updateTermsAction,
} from "../../redux/action/Cms/CmsActions";
import { approvelsAddAPiRequest } from "../../redux/action/Approvels/ApprovelsAction";
import "./cms.css";

const Terms = (props) => {
  const dispatch = useDispatch();
  const adminPermission = useSelector(
    (state) => state.loginData.loginSuccesData
  );
  const [cmsTerms, setCmsTerms] = useState("");

  useEffect(() => {
    getCmsTerms();
  }, []);

  const getCmsTerms = async () => {
    try {
      const response = await dispatch(getCmsTermsData({}));
      const getCmsTermsSuccess = response?.payload;
      // console.log("get--", getCmsPrivacySuccess?.data);
      // console.log("get--", cmsPrivacy);

      if (getCmsTermsSuccess) {
        if (getCmsTermsSuccess?.status == 200) {
          setCmsTerms(getCmsTermsSuccess?.data);
        } else {
          swal({
            title: "Error!",
            text: getCmsTermsSuccess?.data?.message,
            buttons: false,
            icon: "error",
            timer: 2000,
          });
        }
      }
    } catch (err) {
      swal({
        title: "Error",
        text: err?.message,
        buttons: false,
        icon: "error",
        timer: 2000,
      });
    }
  };
  const handleEditSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const body = {
      content: cmsTerms,
    };
    console.log("bo--", body);
    // dispatch(updatePrivacyAction(privacy));
    if (adminPermission?.permissions) {
      if (adminPermission.permissions[0].others.blockAdmin === "full_access") {
        dispatch(updateTermsAction(body));
      } else {
        var sendReq = {
          action: "Add Admin",
          action_status: 1,
          comment: "New admin",
          details: body,
        };
      }
      dispatch(approvelsAddAPiRequest(sendReq));
      props.onClick();
    } else {
      dispatch(updateTermsAction(body));
    }
  };

  return (
    <>
      {/* <h1>Terms and Conditions </h1> */}
      <form action="" onSubmit={handleEditSubmit}>
        <div style={{ margin: "10px" }}>
          <CKEditor
            editor={ClassicEditor}
            data={cmsTerms}
            onChange={(event, editor) => setCmsTerms(editor.getData())}
          />
        </div>
        <button type="submit" class="btn green-btn-privacy">
          Update
        </button>
      </form>
    </>
  );
};

export default Terms;
