// import "./App.css";
// import Routes from "./routes/routes";
// import "../src/assets/css/icon.css";
// import "../src/assets/css/font-awesome.min.css";
// import { useEffect, useState } from "react";
// import { useHistory } from "react-router-dom";
// import { useLocation } from "react-router-dom";

// import { checkAdminStatus } from "./utils/adminStatusCheck";

// function App() {
//   useEffect(() => {
//     checkAdminStatus();
//   }, []);

//   return <Routes />;
// }

// export default App;

import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Routes from "./routes/routes";
import { checkAdminStatus } from "./utils/adminStatusCheck";
import "./App.css";
import "../src/assets/css/icon.css";
import "../src/assets/css/font-awesome.min.css";

function App() {
  const location = useLocation();

  useEffect(() => {
    console.log(`Current location: ${location.pathname}`);
    checkAdminStatus();
  }, [location]);

  return <Routes />;
}

export default App;





