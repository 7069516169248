import React, { useEffect, useState } from "react";
// import '../AdminManagment/AdminManagment.css'
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import {
  addFaqCategory,
  addQuestionAction,
} from "../../../../redux/action/Cms/CmsActions";
// import {addAdmin } from '../../../redux/action/SettingAction/SettingAction'
import { approvelsAddAPiRequest } from "../../../../redux/action/Approvels/ApprovelsAction";
import {
  deleteFaqCategory,
  getAllCategoryAction,
  getAllFaqAction,
  getAllTopicsAction,
} from "../../../../redux/action/Cms/CmsActions";
// import { approvelsAddAPiRequest } from '../../../redux/action/Approvels/ApprovelsAction'
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  Typography,
  Button,
  ButtonGroup,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { Create, Delete, CheckCircle, ExpandMore } from "@material-ui/icons";

import AddQuestionsModal from "./AddQuestionsModal";
import EditQuestions from "./EditQuestions";
import { toast } from "react-toastify";
// import { Accordion, AccordionHeader, AccordionItem, AccordionBody, Card } from 'react-bootstrap';

const Questions = () => {
  const dispatch = useDispatch();
  const allFaqData = useSelector((state) => state.cmsData.getAllFaqByTopic);
  const topicsData = useSelector((state) => state.cmsData.getAllTopics);
  const successMessage = useSelector(
    (state) => state.settingData.addAdminSuccussMsg
  );
  const adminPermission = useSelector(
    (state) => state.loginData.loginSuccesData
  );
  const [isAddAdmin, setIsAddAdmin] = useState(false);
  const [isEditAdmin, setIsEditAdmin] = useState(false);
  const [isEditAdminData, setIsEditAdminData] = useState("");

  const [isFirst, setIsFirst] = useState(false);
  const [successApiMsg, setSuccessApiMsg] = useState("");

  const categoryData = useSelector((state) => state.cmsData.getAllCategory);

  const [category, setCategory] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [topic, setTopic] = useState("");
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  // console.log("cat--", categoryData?.data?.result);
  useEffect(() => {
    if (isFirst) {
      if (successMessage?.message) {
        setSuccessApiMsg(successMessage.message);
        // setTimeout(() => props.onClick(), 2000);
      }
    }
  }, [successMessage]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const arr = category;
    var xyz = arr.split(",");
    // setCategoryId(xyz[0]);

    const arr1 = topic;
    var abc = arr1.split(",");

    let json = {
      categoryId: xyz[0],
      category: xyz[1],
      topicId: abc[0],
      topic: abc[1],
      question: question,
      answer: answer,
    };
    // e.target.reset();
    console.log("ja----", json);

    if (adminPermission?.permissions) {
      if (adminPermission.permissions[0].others.blockAdmin === "full_access") {
        dispatch(addQuestionAction(json));
      } else {
        var sendReq = {
          action: "Add Admin",
          action_status: 1,
          comment: "New admin",
          details: json,
        };
      }
      dispatch(approvelsAddAPiRequest(sendReq));
    } else {
      const res = await dispatch(addQuestionAction(json));
      console.log("res--", res);

      const success = res[0].payload;

      if (success.status == true) {
        toast.success("Question added successfully");
      }

      // props.onClick();
      // setQuestion("");
      e.target.reset();
      setCategory("");
      setTopic("");
    }

    // console.log("e----", e);
  };

  useEffect(() => {
    dispatch(getAllFaqAction());
  }, []);
  const addAdminModelClose = () => {
    setIsAddAdmin(false);
  };

  const renderTableData = () => {
    return (
      <div>
        {allFaqData &&
          allFaqData.data.map((option) => {
            return (
              <>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography>{option.question}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>{option.answer}</Typography>
                  </AccordionDetails>
                </Accordion>
              </>
            );
          })}
      </div>
    );
  };

  return (
    <>
      {/* <AddQuestionsModal
        isModalOpen={isAddAdmin}
        onClick={() => addAdminModelClose()}
      /> */}

      <div
        style={{
          margin: "20px",
          padding: "10px 20px",
          // border: "1px solid #bdbdbd",
          borderRadius: "5px",
        }}
      >
        <section className="">
          <div className="sec-block alt first">
            <div className="block-single auto">
              <div className="block-heading mb-3">
                <h2> Add Question & Answer</h2>
              </div>
              <div className="">
                <div className="">
                  {/* <div className="table-responsive">{renderTableData()}</div> */}
                  <div className="">
                    <div>
                      <div className="">
                        <div className="">
                          <div className="">
                            <div className="modal-body modal_margin">
                              {successApiMsg ? (
                                <p className="form-text text-center text-success">
                                  {successApiMsg}
                                </p>
                              ) : (
                                ""
                              )}

                              <form
                                onSubmit={handleSubmit}
                                // onSubmit={handleSubmit(addAdminHandler)}
                              >
                                <div className>
                                  <div className="row modified">
                                    <div className="col-sm-6">
                                      <div className="form-group">
                                        <label htmlFor>Add Question</label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          required
                                          onChange={(e) =>
                                            setQuestion(e.target.value)
                                          }
                                          // ref={register({ required: true })}
                                        />
                                        {/* <span className="text-danger">{errors.category && 'required.'}</span> */}
                                      </div>
                                      <div className="form-group">
                                        <label htmlFor>Add Answer</label>
                                        <textarea
                                          type="text"
                                          name="answer"
                                          required
                                          className="form-control"
                                          onChange={(e) =>
                                            setAnswer(e.target.value)
                                          }
                                          // ref={register({ required: true })}
                                        />
                                        {/* <span className="text-danger">{errors.category && 'required.'}</span> */}
                                      </div>
                                    </div>
                                    <div className="col-sm-6">
                                      <div className="col">
                                        <div className="form-group">
                                          <label htmlFor>Select Category</label>
                                          <div className="selectImage">
                                            <select
                                              className="custom-select"
                                              name="id"
                                              // ref={register({ required: true })}
                                              onChange={(e) =>
                                                setCategory(e.target.value)
                                              }
                                              // value={category}
                                            >
                                              <option>Select </option>
                                              {categoryData &&
                                                categoryData?.data?.result.map(
                                                  (option) => {
                                                    // console.log(option);
                                                    return (
                                                      <option
                                                        key={option.id}
                                                        value={[
                                                          option.id,
                                                          option.category,
                                                        ]}
                                                      >
                                                        {option.category}
                                                      </option>
                                                    );
                                                  }
                                                )}
                                            </select>
                                            {/* <span className="text-danger">{errors.delete_transaction && 'required.'}</span> */}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col my-2 mt-4">
                                        <div className="form-group">
                                          <label htmlFor>Select Topic</label>
                                          <div className="selectImage">
                                            <select
                                              className="custom-select"
                                              name="topic"
                                              onChange={(e) =>
                                                setTopic(e.target.value)
                                              }
                                            >
                                              <option>Select</option>
                                              {topicsData &&
                                                topicsData?.data?.data.map(
                                                  (option) => {
                                                    return (
                                                      <option
                                                        value={[
                                                          option.id,
                                                          option.topic,
                                                        ]}
                                                      >
                                                        {option.topic}
                                                      </option>
                                                    );
                                                  }
                                                )}
                                            </select>
                                            {/* <span className="text-danger">{errors.delete_transaction && 'required.'}</span> */}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-sm-6">
                                        <div className="form-group ml-3 mt-3">
                                          <button
                                            className="btn btn-block green-btn"
                                            type="submit"
                                            // onClick={resetInputField}
                                            name="button"
                                          >
                                            Add
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Questions;
