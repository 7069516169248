import React, { useEffect, useState } from "react";
// import '../AdminManagment/AdminManagment.css'

// import AddNewAdminModal from './AddNewAdminModal'
// import EditAdminModal from './EditAdminModal'
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";

import {
  deleteFaqCategory,
  deleteFaqTopicAction,
  getAllCategoryAction,
  getAllTopicsAction,
} from "../../../../redux/action/Cms/CmsActions";
// import { approvelsAddAPiRequest } from '../../../redux/action/Approvels/ApprovelsAction'
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Typography, Button, ButtonGroup } from "@material-ui/core";
import { Create, Delete, CheckCircle } from "@material-ui/icons";

import AddTopicsModal from "./AddTopicsModal";
import EditTopics from "./EditTopics";
import { toast } from "react-toastify";

const Topics = () => {
  const dispatch = useDispatch();
  const categoryData = useSelector((state) => state.cmsData.getAllCategory);
  const topicsData = useSelector((state) => state.cmsData.getAllTopics);
  console.log("to--", topicsData);
  const successMessage = useSelector(
    (state) => state.settingData.addAdminSuccussMsg
  );
  const adminPermission = useSelector(
    (state) => state.loginData.loginSuccesData
  );
  const [isAddAdmin, setIsAddAdmin] = useState(false);
  const [isEditAdmin, setIsEditAdmin] = useState(false);
  const [isEditAdminData, setIsEditAdminData] = useState("");

  // console.log("cat--", topicsData?.data?.data);
  useEffect(() => {
    dispatch(getAllTopicsAction());
  }, []);
  const addAdminModelClose = () => {
    setIsAddAdmin(false);
  };

  const editAdminModel = (item) => {
    setIsEditAdminData(item);
    setIsEditAdmin(!isEditAdmin);
  };
  const editAdminModelClose = () => {
    setIsEditAdmin(false);
  };
  const deleteAdminById = async (id) => {
    const json = {
      topicId: id,
    };

    // console.log("json--", json);
    const res = await dispatch(deleteFaqTopicAction(json));

    const success = res.payload;
    console.log("res--", success);
    if (success && success.status === true) {
      // console.log("success");
      toast.error(success.message);

      window.location.reload();
    }
  };

  const renderTableData = () => {
    return (
      !isEmpty(topicsData) &&
      topicsData?.data?.data.map((item, index) => {
        const { id, topic } = item;
        return (
          <tr key={index}>
            <td>#{id}</td>
            <td>
              <div className="">
                <span className="sender-txt">{item.category}</span>
              </div>
            </td>
            <td>
              <div className="">
                <span className="sender-txt">{topic}</span>
              </div>
            </td>
            {/* <td>{email}</td>
          <td>{role}</td> */}

            <td>
              <a
                data-toggle="modal"
                data-target="#addAdminModal-2"
                className={`action-link ${
                  adminPermission.role === "admin" &&
                  adminPermission.permissions[0].others.editAdmin ===
                    "view_only"
                    ? "disabled"
                    : ""
                }`}
                onClick={() => {
                  if (
                    adminPermission.role === "admin" &&
                    adminPermission.permissions[0].others.editAdmin ===
                      "view_only"
                  ) {
                    return true;
                  } else {
                    editAdminModel(item);
                  }
                }}
              >
                <i className="icon-icon-edit" />
              </a>

              <a className="action-link" onClick={() => deleteAdminById(id)}>
                <i className="icon-icon-delete" />
              </a>
            </td>
          </tr>
        );
      })
    );
  };

  return (
    <>
      <AddTopicsModal
        isModalOpen={isAddAdmin}
        onClick={() => addAdminModelClose()}
      />
      {isEditAdmin && (
        <EditTopics
          isModalOpen={isEditAdmin}
          onClick={() => editAdminModelClose()}
          data={isEditAdminData}
        />
      )}
      <div
        style={{
          margin: "20px",
          padding: "10px 20px",
          // border: "1px solid #bdbdbd",
          borderRadius: "5px",
        }}
      >
        <section className="">
          <div className="sec-block alt first">
            <div className="block-single auto">
              <div className="block-heading mb-3">
                <h2>Topics</h2>
                <div className="table-btn">
                  <a
                    data-toggle="modal"
                    data-target="#addAdminModal"
                    className={`link green 
                  ${
                    adminPermission.role === "admin" &&
                    adminPermission.permissions[0].others.addAdmin ===
                      "view_only"
                      ? "disabled"
                      : ""
                  }`}
                    onClick={() => {
                      if (
                        adminPermission.role === "admin" &&
                        adminPermission.permissions[0].others.addAdmin ===
                          "view_only"
                      ) {
                        return true;
                      } else {
                        setIsAddAdmin(!isAddAdmin);
                      }
                    }}
                  >
                    Add a new Topic
                  </a>
                </div>
              </div>
              <div className="transaction-main">
                <div className="transaction-table">
                  <div className="table-responsive">
                    <table className="table theme-table">
                      <tbody>
                        <tr>
                          <th>Topic ID</th>
                          {/* <th>Date added</th> */}
                          <th>Category Name</th>
                          <th>Topic Name</th>
                          {/* <th>Email</th>
                          <th>Role</th>
                          <th>Last visit</th> */}
                          <th style={{ width: "160px" }}>Action</th>
                        </tr>

                        {renderTableData()}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Topics;
